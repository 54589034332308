import * as yup from 'yup';


// Group 1 - Orange
const step1Validation = yup.object().shape({
  name: yup.string().required(),
  position: yup.string().required(),
  areaId: yup.number().required().positive().integer().min(1)
});

const step2Validation = yup.object().shape({
  email: yup.string().email().required(),
  phoneNumber: yup.string().min(14).max(15).required(),
  origin: yup.object().test('origin', 'Origem inválida', (value) => 
    { return ((value?.value !== null && value?.value !== '0') || (value?.value === 'other' && value?.other)) }
  )
});


// Group 2 - Pink
const step3Validation = yup.object().shape({
  empresaName: yup.string().required(),
  empresaArea: yup.string().required(),
  empresaLocation: yup.string().required()
});


// Group 3 - Blue
const step4Validation = yup.object().shape({
  demandKind: yup.object().test('demandKind', 'Tipo de demanda inválido', (value) => 
    { return ((value?.value !== null && value?.value !== '0') || (value?.value === 'other' && value?.other)) }
  ),
  productOrService: yup.string().required()
});

const step5Validation = yup.object().shape({
  implementationDate: yup.string().min(10).max(10).required(),
  jobDetails: yup.string().required()
})



export { step1Validation, step2Validation, step3Validation, step4Validation, step5Validation }