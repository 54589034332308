<template>
  <div :key="name">
    <label :class="color" :for="name">{{label}}</label>
    <input autocomplete="off" :name="name" type="text" :maxlength="maxLength" :minlength="minLength" :placeholder="placeholder" :class="color">
  </div>
</template>

<script>
  export default {
    name: 'TextField',
    props: {
      name: String,
      maxLength: Number,
      minLength: Number,
      placeholder: String,
      color: String,
      label: String
    }
  }
</script>

<style scoped>
  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin-bottom: 5vh;
  }
    label {
      width: 100%;

      margin-left: -28px;
      margin-bottom: 18px;

      display: list-item;
      list-style-type: disc;
      list-style-position: inside;

      color: #ffffff;
      text-align: left;
      font-weight: 600;
      font-size: 1.15rem;
      letter-spacing: 2px;
    }
    input {
      width: 100%;

      margin-left: 28px;
      border: none;

      font-family: 'Poppins', sans-serif;
      font-size: 1.55rem;
      color: #ffffff;
      background-color: transparent;
    }
      input::placeholder {
        color: #DEE0E2;
      }
      input:focus {
        outline: none;
      }

  /* Colors */
  label.orange::marker {
    color: #E95A1A
  }
  input.orange {
    border-bottom: 3px solid #E95A1A;
  }
  label.pink::marker {
    color: #D95D93
  }
  input.pink {
    border-bottom: 3px solid #D95D93;
  }
  label.blue::marker {
    color: #0D81AC
  }
  input.blue {
    border-bottom: 3px solid #0D81AC;
  }

</style>