<template>
  <main>
    <header>
      <img alt="Tom Comunicação" src="../../assets/logo-full.svg">
    </header>
    <section>
      <div class="-video">
        <IntroVideoPlayer />
      </div>
      <div class="-message">
        <div class="-container">
          <Title color="grey" spacing="default">Bem-vindo à Tom.</Title>
          <Text alignment="left">
            Em breve, vamos ter uma conversa, mas queremos te conhecer melhor.
            <br>
            Vamos lá?
          </Text>
          <Button color="red" :handler="startForm">
            Vamos!
          </Button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
  import store from '../../store';
  import { getFormOptions } from '../../services/api';

  import IntroVideoPlayer from '../interface/IntroVideoPlayer';

  import Title from '../interface/Title';
  import Text from '../interface/Text';
  import Button from '../interface/Button';

  export default {
    name: 'Welcome',
    components: {
      IntroVideoPlayer,
      Title,
      Text,
      Button
    },
    mounted () {
      this.fetchData();
    },
    methods: {
      fetchData () {
        getFormOptions().then((apiPayload) => {
          store.commit('fillSelectOptions', apiPayload);
        });
      },
      startForm () { store.commit('nextStep') }
    }
  }
</script>

<style scoped>
  main {
    height: 100vh;
    width: calc(100vw - (4rem + 50vw - 50vh));
    z-index: 101;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    background-color: #DEE0E2;
    padding: 0 calc(2rem + 25vw - 25vh);
  }

  header {
    width: calc(100%);
    height: 25%;

    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }
    img {
      width: 133px;
    }

  section {
    width: calc(100%);

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }
    .-video {
      width: 100%;
    } 
    .-message {
      width: 100%;
      max-width: 480px;

      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-content: flex-start;

      margin-left: 90px;
    }
    h1 {
      max-width: 390px;
    }
    p {
      margin: 40px 0;
    }


  /* Mobile */
  @media only screen and (max-width: 768px) {
    main {
      min-height: 100vh;
      height: unset;
      width: calc(100vw);

      align-items: center;
      
      padding: 0;
    }

    header {
      justify-content: center;
      padding: 20px 0;
    }

    section {
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 70px);

      padding: 20px 0 40px;
    }
    .-video {
      margin-bottom: 20px;
    } 
    .-message {
      width: calc(100%);

      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-content: flex-start;

      margin-left: 0;
    }
      .-message>.-container>h1 {
        max-width: 300px;
        text-align: center;
        margin: 0 auto;
      }
    p {
      text-align: center !important;
    }
    span {
      justify-content: center;
    }
  }
</style>