import store from './index';
import { sendFormData } from '../services/api';
import { currentStepInfo } from './getters';

const validateStep = async (context) => {
  const stepInfo = currentStepInfo(context.state);

  const stepPayload = {};

  stepInfo.fields.forEach((field) => {
    if(field.element === 'selectInput') {
      stepPayload[field.name] = {
        value: document.querySelector(`select[name="${field.name}"]`).value,
        other: document.querySelector(`input[name="${field.name}Other"]`).value
      };
    }
    else {
      stepPayload[field.name] = document.querySelector(`${field.element}[name="${field.name}"]`).value;
    }
  });

  stepInfo.validation.isValid(stepPayload)
    .then((validationResult) => {
      context.commit(validationResult ? 'nextStep' : 'setInvalid');
    });
};


const submitForm = async (context) => {
  const formData = context.state.form;

  store.commit('setSubmitLoading');
  const response = await sendFormData(formData);
  store.commit('setSubmitLoading');

  if(response.message === 'success') {
    store.commit('setSubmitFinished');
  }
  else {
    store.commit('setSubmitFinished', response.errors);
  }
}


export { validateStep, submitForm }