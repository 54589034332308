<template>
  <footer>
    <img alt="Tom Comunicação" src="../../assets/logo.svg">
    <ProgressBar v-bind:color="color" :percentageStatus="percentageStatus" />
  </footer>
</template>

<script>

  import ProgressBar from '../interface/ProgressBar';

  export default {
    name: 'FormFooter',
    components: {
      ProgressBar
    },
    props: {
      color: String,
      percentageStatus: Number
    }
  }
</script>

<style scoped>
  footer {
    width: calc(100vw - (4rem + 50vw - 50vh));
    height: 5vh;
    min-height: 40px;
    max-height: 57px;

    position: absolute;
    bottom: calc(10vh - 40px);

    display: flex;
    flex-flow: row;
    justify-content: space-between;

    margin: 0 calc(2rem + 25vw - 25vh);
  }
    img {
      width: 133px;
    }

    
  /* Mobile */
  @media only screen and (max-width: 768px) {
    footer {
      width: calc(100vw - 32px);
      height: 52px;

      position: relative;
      bottom: unset;

      margin: calc(35vh + 920px) auto 0;
    }
  }
</style>