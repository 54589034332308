import { totalSteps } from './getters';

const nextStep = (state) => {
  if(state.currentStep < totalSteps(state)) {
    state.currentStep++;
  }
  else {
    state.isSubmitScreen = true;
  }

  setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 500);

  return state
};

const previousStep = (state) => {
  if(state.currentStep > 0) {
    state.currentStep--;
  }
  return state
};

const setInvalid = (state) => {
  state.validationFail = !state.validationFail;
  return state
}

const setSubmitScreen = (state) => {
  state.isSubmitScreen = !state.isSubmitScreen;
  return state
}

const setSubmitLoading = (state) => {
  state.submitLoading = !state.submitLoading;
  return state
}

const setSubmitFinished = (state) => {
  state.submitSent = !state.submitSent;
  return state
}

const setSubmitErrors = (state, payload) => {
  state.submitErrors = payload;
  return state
}

const fillSelectOptions = (state, payload) => {
  const newFormData = state.form;

  payload.fields.forEach(field => {
    
    newFormData.forEach(group => {

      group.steps.forEach(step => {

        step.fields.forEach(formField => {

          if(formField.name === field.fieldName) {
            formField.options = field.options;
          }
          
        })
      });
    });
  });
  state.form = newFormData;
  state.apiDataReady = true;
  return state
};



export { 
  nextStep,
  previousStep,
  setInvalid,
  setSubmitScreen,
  setSubmitLoading,
  setSubmitFinished,
  setSubmitErrors,
  fillSelectOptions 
}
