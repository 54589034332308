<template>
  <Welcome v-show="currentStepValue === 0" />
  <div v-show="currentStepValue === 1" :class="['transition-box', {'go': currentStepValue === 1}]">&nbsp;</div>
  <Form v-show="currentStepValue >= 1" :step="currentStepValue" />
</template>

<script>
  import Welcome from './components/form/Welcome.vue';
  import Form from './components/form/Form';

  import store from './store';

  export default {
    name: 'App',
    components: {
      Welcome,
      Form
    },
    mounted() {
      this.nextStep = store.nextStep;
      this.previousStep = store.previousStep;
    },
    computed: {
      currentStepValue () { return store.state.currentStep }
    }
  }
</script>

<style>
  #app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0;
    position: relative;
  }

  body {
    margin: 0;
    overflow-y: hidden;
  }

  div.transition-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;

    width: 100vw;
    height: 100vh;

    background-color: #EE804F;

  }

    div.transition-box.go {
      animation: boxAnimation 0.4s cubic-bezier(0.65, 0, 0.35, 1) 1 0.5s normal both;
    }

    @keyframes boxAnimation {
      0% 
        {
          opacity: 1;
          visibility: visible;
        }
      100%   
        {
          opacity: 0;
          visibility: hidden;
        }
    }

    
  /* Mobile */
  @media only screen and (max-width: 768px) {
    body {
      width: 100vw;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    #app {
      width: 100vw;
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
</style>
