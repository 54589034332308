
const totalSteps = (state) => {
  // Returns the total ammount of steps by counting how many steps each group has
  const form = state.form;
  let totalStepCount = 0;

  form.forEach((group) => {
    totalStepCount += group.steps.length;
  });

  return totalStepCount
}


const currentStepInfo = (state) => {
  // Loops through all of the steps and, when the current step is found, return its group's color and number
  const form = state.form;
  let totalStepCount = 0;
  let stepColor;
  let currentGroup;
  let validationFunc;
  let stepFields;

  form.forEach((group) => {

    group.steps.forEach((step) => {
      totalStepCount++;

      if(totalStepCount === state.currentStep) {
        stepColor = group.color;
        currentGroup = group.group;
        validationFunc = step.validation;
        stepFields = step.fields;
      }
    });
  });

  return {
    color: stepColor,
    group: currentGroup,
    validation: validationFunc,
    fields: stepFields
  }
}



const groupInfo = (state, groupNumber) => {
  // Returns information about the steps inside a group, by number
  const form = state.form;
  let currentStep = 0;
  let response;

  form.forEach((group) => {

    const firstStepInGroup = currentStep+1;
    const lastStepInGroup = currentStep+group.steps.length;

    group.steps.forEach(() => {
      currentStep++;

      if(groupNumber === group.group) {
        response = {
          firstStep: firstStepInGroup,
          lastStep: lastStepInGroup,
          stepCount: group.steps.length
        };
      }
    });
  });

  return response
}

export { totalSteps, currentStepInfo, groupInfo }