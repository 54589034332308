import { createStore } from 'vuex'
import defaultFormObject from './defaultFormObject';
import { totalSteps, currentStepInfo, groupInfo } from './getters';
import { 
  nextStep,
  previousStep,
  setInvalid,
  setSubmitScreen,
  setSubmitLoading,
  setSubmitFinished,
  setSubmitErrors,
  fillSelectOptions
} from './mutations';
import { validateStep, submitForm } from './actions';

const store = createStore({
  state () {
    return defaultFormObject
  },
  getters: {
    totalSteps: state => totalSteps(state),
    currentStepInfo: state => currentStepInfo(state),
    groupInfo: state => groupNumber => groupInfo(state, groupNumber)
  },
  mutations: {
    nextStep (state) { nextStep(state) },
    previousStep (state) { previousStep(state) },
    setInvalid (state) { setInvalid(state) },
    setSubmitScreen (state) { setSubmitScreen(state) },
    setSubmitLoading (state) { setSubmitLoading(state) },
    setSubmitFinished (state) { setSubmitFinished(state) },
    setSubmitErrors (state, payload) { setSubmitErrors(state, payload) },
    setFormData (state, payload) { state.form = payload },
    fillSelectOptions (state, payload) { fillSelectOptions(state, payload) }
  },
  actions: {
    validateStep (context) { validateStep(context) },
    submitForm (context) { submitForm(context) }
  }
});

export default store;