<template>
  <div class="-container">
      <video controls>
        <source src="../../assets/video.mp4" type="video/mp4">
        Seu browser não suporta a tag video
      </video>
      <div class="-red-box">&nbsp;</div>
      <div class="-dot-box">&nbsp;</div>
  </div>
</template>

<script>
  export default {
    name: 'IntroVideoPlayer',
    props: {
      srcList: Array
    }
  }
</script>

<style scoped>
  .-container{
    position: relative;
  }
    video {
      width: 100%;
      z-index: 2;

      position: relative;
    }
    .-red-box {
      width: 100%;
      height: 100%;
      z-index: 1;

      position: absolute;
      top: -1vw;
      left: -1vw;

      background-color: #E53212;
    }
    .-dot-box {
      width: 100%;
      height: 100%;
      z-index: 1;

      position: absolute;
      bottom: -1vw;
      right: -1vw;

      background: url('../../assets/dots.svg') round 0 0;
    }
</style>