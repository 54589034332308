<template>
  <h1 :class="[color, spacing, size]">
    <slot></slot>
  </h1>
</template>

<script>
  export default {
    name: 'Title',
    props: {
      color: String,
      spacing: String,
      size: String
    }
  }
</script>

<style scoped>
  h1 {
    margin: 0;

    font-family: 'Abril Fatface', cursive;
    font-size: 3.7rem;
    letter-spacing: 2px;
    font-weight: 400;

    text-transform: uppercase;
    text-align: left;
  }

  /* Colors */
  .grey {
    color: #6C6C6C;
  }
  .orange {
    color: #E95A1A;
  }
  .pink {
    color: #D95D93;
  }
  .blue {
    color: #0D81AC;
  }

  /* Spacing */
  .default {
    line-height: 4rem;
  }
  .long {
    line-height: 6rem;
  }

  /* Size */
  .small {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }


  /* Mobile */
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 2.7rem;
      line-height: 3.5rem !important;
    }
    .small {
      font-size: 2rem;
      line-height: 2.4rem !important;
    }
  }

</style>