<template>
  <div>
    <FormInput
      v-for="field in step" 
      v-bind:field="field"
      :group="group"
      :inputType="field.type"
      :key="`formInput${field.name}`"
    />
    <Button :color="group.color" :isNextStep="true">
      Ok
    </Button>
  </div>
</template>

<script>

  import FormInput from '../interface/inputs/FormInput';
  import Button from '../interface/Button';

  export default {
    name: 'FormStep',
    components: {
      FormInput,
      Button
    },
    props: {
      step: Array,
      group: Object,
    }
  }
</script>

<style scoped>
  /* Mobile */
  @media only screen and (max-width: 768px) {
    div {
      width: 42%;
    }
    span {
      justify-content: center;
    }
  }

</style>