<template>
  <TextField
    v-if="inputType === 'textField'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'Digite aqui...'"
    :color="group.color"
    :label="field.label"
  />
  <Select
    v-if="inputType === 'select'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'Digite aqui...'"
    :color="group.color"
    :label="field.label"
    v-bind:options="field.options"
  />
  <SelectOrOther
    v-if="inputType === 'selectOrOther'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'Digite aqui...'"
    :color="group.color"
    :label="field.label"
    v-bind:options="field.options"
    v-bind:otherText="field.otherText"
  />
  <DateField
    v-if="inputType === 'dateField'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'dd/mm/aaaa'"
    :color="group.color"
    :label="field.label"
  />
  <TextArea
    v-if="inputType === 'textArea'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'Digite aqui...'"
    :color="group.color"
    :label="field.label"
  />
  <PhoneNumber
    v-if="inputType === 'phoneNumber'"
    :name="field.name"
    v-bind:maxLength="field.maxLength || 240"
    v-bind:minLength="field.minLength || 5"
    :placeholder="field.description || 'Digite aqui...'"
    :color="group.color"
    :label="field.label"
  />
</template>

<script>
  import TextField from './TextField';
  import Select from './Select';
  import SelectOrOther from './SelectOrOther';
  import DateField from './DateField';
  import TextArea from './TextArea';
  import PhoneNumber from './PhoneNumber';

  export default {
    name: 'FormInput',
    props: {
      field: Object,
      group: Object,
      inputType: String
    },
    components: {
      TextField,
      Select,
      SelectOrOther,
      DateField,
      TextArea,
      PhoneNumber
    }
  }
</script>

<style>

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
      -webkit-text-fill-color: #ffffff !important;
      transition: background-color 5000s ease-in-out 0s;
  }
  
  /* Mobile */
  @media only screen and (max-width: 768px) {
    input,select,textarea {
      width: calc(100% - 28px) !important;
    }
    label {
      margin-left: 0 !important;
    }
  }
</style>