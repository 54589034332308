<template>
  <div :key="name">
    <label :class="color" :for="name">{{label}}</label>
    <select :name="name" v-bind:class="[color, 'null-select']" :id="`select_${name}`" v-on:change="selectClass">
      <option :value="0" hidden>Escolha aqui</option>
      <option v-for="option in options" :value="option.value" v-bind:key="`select_option_${option.name}_${option.value}`">{{option.label}}</option>
      <option :value="'other'">{{otherText || 'Outros'}}</option>
    </select>
    <input :name="`${name}Other`" :id="`other_${name}`" type="text" :maxlength="maxLength" :minlength="minLength" :placeholder="placeholder" :class="color">
  </div>
</template>

<script>

  export default {
    name: 'SelectOrOther',
    components: {
    },
    props: {
      name: String,
      maxLength: Number,
      minLength: Number,
      placeholder: String,
      color: String,
      label: String,
      options: Array,
      otherText: String
    },
    computed: {
      selectIsOther() { return document.querySelector(`#select_${this.name}`)?.value === 'other' }
    },
    methods: {
      selectClass () {
        const selectNode = document.querySelector(`#select_${this.name}`);
        const otherNode = document.querySelector(`#other_${this.name}`);
        if(selectNode && selectNode?.value === 'other') {
          selectNode.classList.remove('null-select');
          otherNode.classList.add('-show-other');
        }
        else if(selectNode && selectNode?.value !== 0) {
          selectNode.classList.remove('null-select');
          otherNode.classList.remove('-show-other');
        }
        else if(selectNode) {
          selectNode.classList.add('null-select');
          otherNode.classList.remove('-show-other');
        }
      }
    }
  }
</script>

<style scoped>
  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin-bottom: 5vh;
  }
    label {
      width: 100%;

      margin-left: -28px;
      margin-bottom: 18px;

      display: list-item;
      list-style-type: disc;
      list-style-position: inside;

      color: #ffffff;
      text-align: left;
      font-weight: 600;
      font-size: 1.15rem;
      letter-spacing: 2px;
    }
    select,input {
      width: 100%;

      margin-left: 28px;
      border: none;

      font-family: 'Poppins', sans-serif;
      font-size: 1.55rem;
      color: #ffffff;
      background-color: transparent;
    }
      select::placeholder,input::placeholder {
        color: #DEE0E2;
      }
      select:focus,input:focus {
        outline: none;
      }
      select.null-select {
        color: #DEE0E2;
      }
      input {
        margin-left: 72px;
        margin-top: 12px;
        width: calc(100% - 44px);
        display: none;
      }
        input.-show-other {
          display: block;
        }

  /* Colors */
  label.orange::marker {
    color: #E95A1A
  }
  select.orange,input.orange {
    border-bottom: 3px solid #E95A1A;
  }
  label.pink::marker {
    color: #D95D93
  }
  select.pink,input.pink {
    border-bottom: 3px solid #D95D93;
  }
  label.blue::marker {
    color: #0D81AC
  }
  select.blue,input.blue {
    border-bottom: 3px solid #0D81AC;
  }
</style>