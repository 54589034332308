<template>
  <div :key="name">
    <label :class="color" :for="name">{{label}}</label>
    <input autocomplete="off" :name="name" :id="`date_${name}`" type="text" :maxlength="maxLength" :minlength="minLength" :placeholder="placeholder" :class="color" @keyup="formatDate">
  </div>
</template>

<script>
  export default {
    name: 'DateField',
    props: {
      name: String,
      maxLength: Number,
      minLength: Number,
      placeholder: String,
      color: String,
      label: String
    },
    methods: {
      formatDate() {
        const dateInput = document.querySelector(`#date_${this.name}`);
        
        const pass = dateInput.value;
        const expr = /[0123456789]/;

        for (let i = 0; i < pass.length; i++) {
          const lchar = dateInput.value.charAt(i);
          const nchar = dateInput.value.charAt(i + 1);

          if (i == 0) {
            if ((lchar.search(expr) != 0) || (lchar > 3)) {
              dateInput.value = '';
            }

          } else if (i == 1) {

            if (lchar.search(expr) != 0) {
              const tst1 = dateInput.value.substring(0, (i));
              dateInput.value = tst1;
              continue;
            }

            if ((nchar != '/') && (nchar != '')) {
              const tst1 = dateInput.value.substring(0, (i) + 1);

              const tst2 = (nchar.search(expr) != 0) ? 
                dateInput.value.substring(i + 2, pass.length) : dateInput.value.substring(i + 1, pass.length);

              dateInput.value = tst1 + '/' + tst2;
            }

          } else if (i == 4) {

            if (lchar.search(expr) != 0) {
              const tst1 = dateInput.value.substring(0, (i));
              dateInput.value = tst1;
              continue;
            }

            if ((nchar != '/') && (nchar != '')) {
              const tst1 = dateInput.value.substring(0, (i) + 1);

              const tst2 = (nchar.search(expr) != 0) ? 
                dateInput.value.substring(i + 2, pass.length) : dateInput.value.substring(i + 1, pass.length);

              dateInput.value = tst1 + '/' + tst2;
            }
          }

          if (i >= 6) {
            if (lchar.search(expr) != 0) {
              let tst1 = dateInput.value.substring(0, (i));
              dateInput.value = tst1;
            }
          }
        }

        if (pass.length > 10)
          dateInput.value = dateInput.value.substring(0, 10);
        return true;
      
      }
    }
  }
</script>

<style scoped>
  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin-bottom: 5vh;
  }
    label {
      width: 100%;

      margin-left: -28px;
      margin-bottom: 18px;

      display: list-item;
      list-style-type: disc;
      list-style-position: inside;

      color: #ffffff;
      text-align: left;
      font-weight: 600;
      font-size: 1.15rem;
      letter-spacing: 2px;
    }
    input {
      width: 100%;

      margin-left: 28px;
      border: none;

      font-family: 'Poppins', sans-serif;
      font-size: 1.55rem;
      color: #ffffff;
      background-color: transparent;
    }
      input::placeholder {
        color: #DEE0E2;
      }
      input:focus {
        outline: none;
      }

  /* Colors */
  label.orange::marker {
    color: #E95A1A
  }
  input.orange {
    border-bottom: 3px solid #E95A1A;
  }
  label.pink::marker {
    color: #D95D93
  }
  input.pink {
    border-bottom: 3px solid #D95D93;
  }
  label.blue::marker {
    color: #0D81AC
  }
  input.blue {
    border-bottom: 3px solid #0D81AC;
  }

</style>