<template>
  <div :key="name">
    <label :class="color" :for="name">{{label}}</label>
    <select :name="name" v-bind:class="[color, 'null-select']" :id="`select_${name}`" v-on:change="selectClass">
      <option :value="0" hidden>Escolha aqui</option>
      <option v-for="option in options" :value="option.value" v-bind:key="`select_option_${option.name}_${option.value}`">{{option.label}}</option>
    </select>
  </div>
</template>

<script>

  export default {
    name: 'Select',
    components: {
    },
    props: {
      name: String,
      placeholder: String,
      color: String,
      label: String,
      options: Array
    },
    methods: {
      selectClass () {
        const selectNode = document.querySelector(`#select_${this.name}`);
        if(selectNode && selectNode?.value !== 0) {
          selectNode.classList.remove('null-select');
        }
        else if(selectNode) {
          selectNode.classList.add('null-select');
        }
      }
    }
  }
</script>

<style scoped>
  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin-bottom: 5vh;
  }
    label {
      width: 100%;

      margin-left: -28px;
      margin-bottom: 18px;

      display: list-item;
      list-style-type: disc;
      list-style-position: inside;

      color: #ffffff;
      text-align: left;
      font-weight: 600;
      font-size: 1.15rem;
      letter-spacing: 2px;
    }
    select {
      width: 100%;

      margin-left: 28px;
      border: none;

      font-family: 'Poppins', sans-serif;
      font-size: 1.55rem;
      color: #ffffff;
      background-color: transparent;
    }
      select.null-select {
        color: #DEE0E2;
      }
      select::placeholder {
        color: #DEE0E2;
      }
      select:focus {
        outline: none;
      }

  /* Colors */
  label.orange::marker {
    color: #E95A1A
  }
  select.orange {
    border-bottom: 3px solid #E95A1A;
  }
  label.pink::marker {
    color: #D95D93
  }
  select.pink {
    border-bottom: 3px solid #D95D93;
  }
  label.blue::marker {
    color: #0D81AC
  }
  select.blue {
    border-bottom: 3px solid #0D81AC;
  }
</style>