<template>
  <div v-bind:class="[currentStepInfo.color, 'main']" v-bind:v-if="apiDataReady">
    <FormGroup
      v-for="group in groups"
      :color="group.color"
      :title="group.title"
      :key="`formGroup${group.color}`"
      :groupNumber=group.group
      :groupSize=parseInt(group.steps.length)
      v-bind:currentGroup=currentStepInfo.group
      :group=group
    >
      <FormStep
        v-for="(step) in group.steps"
        v-bind:class="['form-step']"
        :key="`formStep${group.color}_${step}_${step.fields.name}`"
        :step=step.fields
        :group=group
      />
    </FormGroup>

    <FormFooter v-bind:color="currentStepInfo.color" v-bind:percentageStatus="percentageStatus" />

    <ValidationErrorModal v-bind:isVisible="validationFail" />
    <SubmitConfirmation v-bind:isVisible="submitScreen" />
  </div>
</template>

<script>
  import store from '../../store';

  import FormFooter from './FormFooter';
  import FormGroup from './FormGroup';
  import FormStep from './FormStep';
  import ValidationErrorModal from './ValidationErrorModal';
  import SubmitConfirmation from './SubmitConfirmation';

  export default {
    name: 'Form',
    components: {
      FormFooter,
      FormGroup,
      FormStep,
      ValidationErrorModal,
      SubmitConfirmation
    },
    props: {
      step: Number
    },
    created() {
      this.apiDataReady = store.state.apiDataReady;
      this.groups = store.state.form;
    },
    computed: {
      validationFail() { return store.state.validationFail },
      submitScreen() { return store.state.isSubmitScreen },
      currentStepInfo() { return store.getters.currentStepInfo },
      percentageStatus() { return 100/(store.getters.totalSteps)*(store.state.currentStep - 1) },
    }
  }
</script>

<style scoped>
  .main {
    height: calc(100vh);
    width: calc(100vw);

    overflow-y: hidden;

    padding: 0;
  }

  /* Steps */


  /* Colors */
  .main.orange {
    background-color: #EE804F;
  }
  .main.pink {
    background-color: #ED7BAC;
  }
  .main.blue {
    background-color: #1E9CCB;
  }

    
  /* Mobile */
  @media only screen and (max-width: 768px) {
    .main {
      min-height: calc(100vh);
      height: 100%;
      position: relative;

      overflow-y: scroll;
      overflow-x: hidden;

      display: flex;
      flex-flow: row;
    }
  }
</style>