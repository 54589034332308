<template>
  <p :class="[alignment, size]">
    <slot></slot>
  </p>
</template>

<script>
  export default {
    name: 'Text',
    props: {
      alignment: String,
      size: String
    }
  }
</script>

<style scoped>
  p {
    margin: 0;
    
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.2rem;
    letter-spacing: 1px;

    color: #6C6C6C;
  }

  /* Alignment */
  .center {
    text-align: center;
  }
  .left {
    text-align: left;
  }

  /* Size */
  .small {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

</style>