<template>
  <section v-bind:class="[groupClass, color]">
    <div class="-title">
        <Title :color="color" spacing="long">{{title}}</Title>
    </div>
    <div class="-form">
      <div v-bind:class="['-container', containerStyle.stepClass]" :style="containerStyle.style">
        <slot>

        </slot>
      </div>
    </div>
  </section>
</template>

<script>
  import store from '../../store';

  import Title from '../interface/Title';

  export default {
    name: 'FormGroup',
    components: {
      Title
    },
    props: {
      color: String,
      title: String,
      groupNumber: Number,
      currentGroup: Number,
      groupSize: Number,
      group: Object
    },
    computed: {
      groupClass() {
        let classToReturn;
        if(this.groupNumber < this.currentGroup){
          classToReturn = 'group-is-before'
        }
        else if(this.groupNumber > this.currentGroup){
          classToReturn = 'group-is-after'
        }
        else{
          classToReturn = 'group-is-current'
        }
        return classToReturn;
      },
      containerStyle() {
        const currentStep = store.state.currentStep;
        const { firstStep } = store.getters.groupInfo(this.group.group);
        let stepClass;        

        if(currentStep <= firstStep) {
          stepClass = 'default-step';
        }
        else if(currentStep > firstStep) {
          stepClass = 'reverse-step';
        }

        return {
          style: {
            height: window.innerWidth >= 768 ? `${this.groupSize*100}vh` : 'unset'
          },
          stepClass: stepClass
        }
      }
    }
  }
</script>

<style scoped>
  section {
    width: calc(100vw - (4rem + 50vw - 50vh));
    flex: 100%;
    
    position: absolute;
    top: 0;

    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: stretch;

    padding: calc(20vh - 80px) calc(2rem + 25vw - 25vh);

    transform: translateY(0);
    transition: all 0.6s cubic-bezier(0.65, 0, 0.35, 1) 0s;
  }
    .-title {
      width: 100%;
    } 
      h1 {
        max-width: 600px;
      }
    .-form {
      width: 100%;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-content: flex-start;

      margin-left: 90px;
    }
      .-container {
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        /* Animatable attributes */
        transform: translateY(0);
        flex-flow: column;
        opacity: 1;
      }
      span {
        margin-top: 70px;
      }
    
    /* Group switching */
    .group-is-before {
      transform: translateY(-120%);
      transition-delay: 0.2s;
    }
    .group-is-current {
      transform: translateY(0);
      transition-delay: 0s;
    }
    .group-is-after {
      transform: translateY(120%);
      transition-delay: 0.2s;
    }


  /* Colors */
  section.orange {
    background-color: #EE804F;
  }
  section.pink {
    background-color: #ED7BAC;
  }
  section.blue {
    background-color: #1E9CCB;
  }

  /* Step-switch  */
  @keyframes stepSwitchAnimation {
    0%   
      {
        transform: translateY(0);
        flex-flow: column;
        opacity: 1;
      }
    49%   
      {
        transform: translateY(-50%);
        flex-flow: column;
        opacity: 0;
      }
    50% 
      {
        transform: translateY(-100%);
        flex-flow: column-reverse;
        opacity: 0;
      }
    51% 
      {
        transform: translateY(50%);
        flex-flow: column-reverse;
        opacity: 0;
      }
    100% 
      {
        transform: translateY(0);
        flex-flow: column-reverse;
        opacity: 1;
      }
  }
  @keyframes stepSwitchAnimationBack {
    0% 
      {
        transform: translateY(0);
        flex-flow: column-reverse;
        opacity: 1;
      }
    49% 
      {
        transform: translateY(100%);
        flex-flow: column-reverse;
        opacity: 1;
      }
    50% 
      {
        transform: translateY(100%);
        flex-flow: column;
        opacity: 0;
      }
    51%   
      {
        transform: translateY(-50%);
        flex-flow: column;
        opacity: 0;
      }
    100%   
      {
        transform: translateY(0);
        flex-flow: column;
        opacity: 1;
      }
  }
  /* Mobile animations */
    @keyframes stepSwitchAnimationMobile {
      0%   
        {
          transform: translateX(0);
          flex-flow: row;
          opacity: 1;
        }
      49%   
        {
          transform: translateX(-50%);
          flex-flow: row;
          opacity: 0;
        }
      50% 
        {
          transform: translateX(-100%);
          flex-flow: row-reverse;
          opacity: 0;
        }
      51% 
        {
          transform: translateX(50%);
          flex-flow: row-reverse;
          opacity: 0;
        }
      100% 
        {
          transform: translateX(0);
          flex-flow: row-reverse;
          opacity: 1;
        }
    }
    @keyframes stepSwitchAnimationBackMobile {
      0% 
        {
          transform: translateX(0);
          flex-flow: row-reverse;
          opacity: 1;
        }
      49% 
        {
          transform: translateX(100%);
          flex-flow: row-reverse;
          opacity: 1;
        }
      50% 
        {
          transform: translateX(100%);
          flex-flow: row;
          opacity: 0;
        }
      51%   
        {
          transform: translateX(-50%);
          flex-flow: row;
          opacity: 0;
        }
      100%   
        {
          transform: translateX(0);
          flex-flow: row;
          opacity: 1;
        }
    }

  .reverse-step {
    animation: stepSwitchAnimation 0.6s cubic-bezier(0.65, 0, 0.35, 1) 1 normal;
    flex-flow: column-reverse;
  }
  .default-step {
    animation: stepSwitchAnimationBack 0.6s cubic-bezier(0.65, 0, 0.35, 1) 1 normal;
    flex-flow: column;
  }


  /* Mobile */
  @media only screen and (max-width: 768px) {
    section {
      flex-flow: column;
      padding: 0 calc(2rem + 25vw - 25vh) 20px;
      width: calc(100vw);
      flex: unset;

      height: 100%;

      justify-content: flex-start;

      position: absolute;
      top: 0;
      left: 0;
    }
    .-form {
      width: calc(100% - 50px);
      margin: 10% auto 0;
    }
    .-title {
      width: calc(100% - 70px);
      margin: 10% auto;
    } 
      .-container {
        width: 200vw;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
      }

    .reverse-step {
      animation: stepSwitchAnimationMobile 0.6s cubic-bezier(0.65, 0, 0.35, 1) 1 normal;
      flex-flow: row-reverse;
    }
    .default-step {
      animation: stepSwitchAnimationBackMobile 0.6s cubic-bezier(0.65, 0, 0.35, 1) 1 normal;
      flex-flow: row;
    }
    
    .group-is-before {
      transform: translateX(-120%);
    }
    .group-is-current {
      transform: translateX(0);
    }
    .group-is-after {
      transform: translateX(120%);
    }
  }
</style>