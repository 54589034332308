<template>
  <div :key="name">
    <label :class="color" :for="name">{{label}}</label>
    <input autocomplete="off" :name="name" :id="`phone_${name}`" type="text" :maxlength="maxLength" :minlength="minLength" :placeholder="placeholder" :class="color" @keyup="formatPhone">
  </div>
</template>

<script>
  export default {
    name: 'PhoneNumber',
    props: {
      name: String,
      maxLength: Number,
      minLength: Number,
      placeholder: String,
      color: String,
      label: String
    },
    methods: {
      formatPhone () {
        const phoneInput = document.querySelector(`#phone_${this.name}`);
        setTimeout(() => {
          let newValue = phoneInput.value.replace(/\D/g, "");
          newValue = newValue.replace(/^0/, "");
          if (newValue.length > 10) {
            newValue = newValue.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
          } else if (newValue.length > 5) {
            newValue = newValue.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
          } else if (newValue.length > 2) {
            newValue = newValue.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
          } else {
            newValue = newValue.replace(/^(\d*)/, "($1");
          }
          if (newValue != phoneInput.value) {
            phoneInput.value = newValue;
          }
        }, 1);
      }
    }
  }
</script>

<style scoped>
  div {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin-bottom: 5vh;
  }
    label {
      width: 100%;

      margin-left: -28px;
      margin-bottom: 18px;

      display: list-item;
      list-style-type: disc;
      list-style-position: inside;

      color: #ffffff;
      text-align: left;
      font-weight: 600;
      font-size: 1.15rem;
      letter-spacing: 2px;
    }
    input {
      width: 100%;

      margin-left: 28px;
      border: none;

      font-family: 'Poppins', sans-serif;
      font-size: 1.55rem;
      color: #ffffff;
      background-color: transparent;
    }
      input::placeholder {
        color: #DEE0E2;
      }
      input:focus {
        outline: none;
      }

  /* Colors */
  label.orange::marker {
    color: #E95A1A
  }
  input.orange {
    border-bottom: 3px solid #E95A1A;
  }
  label.pink::marker {
    color: #D95D93
  }
  input.pink {
    border-bottom: 3px solid #D95D93;
  }
  label.blue::marker {
    color: #0D81AC
  }
  input.blue {
    border-bottom: 3px solid #0D81AC;
  }

</style>