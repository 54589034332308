import axios from 'axios';

// Base API 
const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL || 'https://intranet.tomcomunicacao.com.br/leads'
});


// Fetches form data from backend
const getFormOptions = async () => {

  let payload;

  try {
    const response = await api.get('/form-data');
    payload = response.data;
  }
  catch(error) {
    console.error('Erro ao fazer requisição ao servidor');
    console.log(error);
  }

  return payload;
};


// Sends form data to backend
const sendFormData = async (formData) => {

  // Adding each form field to the payload with its value
  const formPayload = {};

  formData.forEach((formGroup) => {

    formGroup.steps.forEach((formStep) => {

      formStep.fields.forEach((field) => {

        if(field.element === 'selectInput') {
          formPayload[field.name] = {
            value: document.querySelector(`select[name="${field.name}"]`).value,
            other: document.querySelector(`input[name="${field.name}Other"]`).value
          };
        }
        else {
          formPayload[field.name] = document.querySelector(`${field.element}[name="${field.name}"]`).value;
        }

      });
    });
  });

  let result;
  // Sending request
  try {
    const response = await api.post('/submit-form', formPayload);
    result = response.data;
  }
  catch(error) {
    console.error('Erro ao fazer requisição ao servidor');
    console.log(error);
    result = error.response?.data;
  }

  return result
  
};


export default api;
export { getFormOptions, sendFormData };