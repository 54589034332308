<template>
  <div class="container">
    
    <div class="info">
      <div class="text">{{percentageStatus}}% completo</div>
      <div class="bar">
        <div class="progress" :style="{width: percentageStatus + '%'}">&nbsp;</div>
      </div>
    </div>

    <div :class="['switches', color]">
      <svg v-on:click="previousStep" xmlns="http://www.w3.org/2000/svg" width="26.108" height="19.18" viewBox="0 0 26.108 19.18">
        <path id="Caminho_4" data-name="Caminho 4" d="M207.186-96.743l-9.563-13.188-9.563,13.188" transform="translate(-184.569 112.431)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
      </svg>
      <svg v-on:click="nextStep" xmlns="http://www.w3.org/2000/svg" width="26.108" height="19.18" viewBox="0 0 26.108 19.18">
        <path id="Caminho_5" data-name="Caminho 5" d="M19.125,13.188,9.563,0,0,13.188" transform="translate(22.617 16.68) rotate(180)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
      </svg>
    </div>

  </div>
</template>

<script>
  import store from '../../store';

  export default {
    name: 'ProgressBar',
    props: {
      color: String,
      percentageStatus: Number
    },
    methods: {
      nextStep() { store.dispatch('validateStep') },
      previousStep() { store.commit('previousStep') },
      mainHeight() {
        const currentGroup = document.querySelector('.group-is-current>.-form');
        const groupHeight = currentGroup?.offsetHeight || 800;

        console.log(groupHeight);

        return {
          minHeight: window.innerWidth >= 768 ? 'unset' : `${groupHeight}px`
        }
      }
    }
  }
</script>

<style scoped>

  .container {
    height: 100%;
    width: 100%;
    max-width: 530px;


    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

    .info {
      height: 100%;
      width: 100%;
      max-width: 400px;

      margin: 0 16px;
    }
      .text {

        margin-bottom: 18px;
        margin-left: 6px;

        color: #ffffff;
        font-weight: 600;
        font-size: 1.1rem;
        text-align: left;
      }
      .bar {
        width: 100%;
        height: 8px;

        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
      }
      .progress {
        height: 8px;

        background-color: #ffffff;
        border-radius: 8px;

        transition: width 0.6s cubic-bezier(0.65, 0, 0.35, 1) 0.2s;
      }

    .switches {
      height: 100%;
      width: 98px;

      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      align-items: center;
    }
      .switches>svg {
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0.05s;
      }
      .switches>svg:hover {
        stroke: #ffffff !important;
      }


    /* Colors */
    .switches.orange>svg {
      stroke: #E95A1A;
    }
    .switches.pink>svg {
      stroke: #D95D93;
    }
    .switches.blue>svg {
      stroke: #0D81AC;
    }


  /* Mobile */
  @media only screen and (max-width: 768px) {
    .switches>svg {
      transform: rotateZ(-90deg);
    }
  }

</style>

