import { step1Validation, step2Validation, step3Validation, step4Validation, step5Validation } from '../services/validation';

export default {
  currentStep: 0,
  apiDataReady: false,
  validationFail: false,
  isSubmitScreen: false,
  submitLoading: false,
  submitSent: false,
  submitErrors: [],
  form: [
    {
      group: 1,
      color: 'orange',
      title: 'Conta pra gente um pouco sobre você?',
      steps: [
        {
          fields: [
            {name: 'name', type: 'textField', element: 'input', label: 'Nome', value: ''},
            {name: 'position', type: 'textField', element: 'input', label: 'Cargo', value: ''},
            {name: 'areaId', type: 'select', element: 'select', label: 'Área', value: '', options: [{value: null, label: ''}]}
          ],
          validation: step1Validation
        },
        {
          fields: [
          {name: 'email', type: 'textField', element: 'input', label: 'E-mail', value: ''},
          {name: 'phoneNumber', type: 'phoneNumber', element: 'input', label: 'Telefone', value: ''},
          {name: 'origin', type: 'selectOrOther', element: 'selectInput', label: 'Como chegou até a Tom', value: '', otherText: 'Outros/Indicação', options: [{value: null, label: ''}]}
          ],
          validation: step2Validation
        } 
      ]
    },
    {
      group: 2,
      color: 'pink',
      title: 'Agora, queremos saber sobre a sua empresa:',
      steps: [
        {
          fields: [
            {name: 'empresaName', type: 'textField', element: 'input', label: 'Nome', value: ''},
            {name: 'empresaArea', type: 'textField', element: 'input', label: 'Ramo de atividade', value: ''},
            {name: 'empresaLocation', type: 'textField', element: 'input', label: 'Praça de atuação', value: ''},
          ],
          validation: step3Validation
        }
      ]
    },
    {
      group: 3,
      color: 'blue',
      title: 'Por último, conta pra gente um pouco do seu desafio?',
      steps: [
        {
          fields: [
            {name: 'demandKind', type: 'selectOrOther', element: 'selectInput', label: 'Tipo de demanda', value: '', options: [{value: null, label: ''}]},
            {name: 'productOrService', type: 'textField', element: 'input', label: 'Produto/Serviço', value: ''}
          ],
          validation: step4Validation
        },
        {
          fields: [
            {name: 'implementationDate', type: 'dateField', element: 'input', label: 'Prazo/Data de implementação', value: ''},
            {name: 'jobDetails', type: 'textArea', element: 'textarea', label: 'Trabalho', value: '', description: 'Campo aberto para descrever', maxLength: 960}
          ],
          validation: step5Validation
        }
      ]
    }
  ]
}