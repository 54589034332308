<template>
  <span>
    <button :class="[color, {'w-100': fullWidth}]" @click="clickHandler">
      <slot></slot>
    </button>
  </span>
</template>

<script>
  import store from '../../store';

  export default {
    name: 'Button',
    props: {
      color: String,
      action: Function,
      fullWidth: Boolean,
      handler: Function,
      isNextStep: Boolean
    },
    methods: {
      clickHandler () {
        if(this.isNextStep) {
          store.dispatch('validateStep');
        }
        else if(this.handler) {
          this.handler();
        }
      }
    }
  }

</script>

<style scoped>
  span {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }
  button {
    display: inline-block;

    margin: 0;
    padding: 12px 60px;
    border-radius: 10px;
    border: none;

    font-family: 'Abril Fatface', cursive;
    font-size: 1.8rem;
    letter-spacing: 2px;
    font-weight: 400;

    color: #ffffff;

    transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1) 0s;
    cursor: pointer;
  }
    button:active,button:focus {
      outline: none;
    }
    button:hover {
      padding: 12px 72px;
    }
    button.w-100:hover {
      transform: scale(1.02);
    }

  /* Colors */
  .grey {
    background-color: #6C6C6C;
  }
  .orange {
    background-color: #E95A1A;
  }
  .pink {
    background-color: #D95D93;
  }
  .blue {
    background-color: #0D81AC;
  }
  .red {
    background-color: #E53212;
  }

  /* Width */
  .w-100 {
    width: 100%;
  }


  /* Mobile */
  @media only screen and (max-width: 768px) {
    button {
      display: inline-block;

      padding: 12px 30px;
      font-size: 1.4rem;
    }
    .w-100 {
      width: 100%;
      margin: 0;
    }
  }

</style>