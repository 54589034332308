<template>
  <main v-bind:class="isVisible ? 'visible' : null">
    <header>
      <img alt="Tom Comunicação" src="../../assets/logo-full.svg">
    </header>
    <section>
      <div class="main-section" v-if="!isLoading && !isSent">
        <Title color="grey">
          Tudo certo?
        </Title>
        <Text>
          Podemos enviar seu formulário?
        </Text>
        <Button color="red" :isNextStep="false" :fullWidth="true" :handler="submitButton">
          Sim. Pode enviar.
        </Button>
        <Button color="red" :isNextStep="false" :fullWidth="true" :handler="backButton">
          Não. Voltar ao formulário.
        </Button>
      </div>
      <div class="main-section" v-if="isLoading && !isSent">
        <Title color="grey">
          Enviando...
        </Title>
      </div>
      <div class="main-section" v-if="isSent">
        <Title color="grey">
          Obrigadx :)
        </Title>
        <Text>
          Sua mensagem foi enviada com sucesso. Em breve, nosso time de novos negócios irá entrar em contato com você.
        </Text>
      </div>
    </section>
  </main>
</template>

<script>
  import store from '../../store';

  import Title from '../interface/Title';
  import Text from '../interface/Text';
  import Button from '../interface/Button';

  export default {
    name: 'SubmitConfirmation',
    components: {
      Title,
      Text,
      Button
    },
    props: {
      isVisible: Boolean
    },
    methods: {
      submitButton () { store.dispatch('submitForm') },
      backButton () { store.commit('setSubmitScreen') }
    },
    computed: {
      isLoading () { return store.state.submitLoading },
      isSent () { return store.state.submitSent }
    }
  }
</script>

<style scoped>

  main {
    height: 100vh;
    width: calc(100vw - (4rem + 50vw - 50vh));

    position: fixed;
    top: 0;
    left: 0;
    padding: 0 calc(2rem + 25vw - 25vh);

    background-color: #DEE0E2;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1) 0s;
    opacity: 0;
    visibility: hidden;
  }

    header {
      width: calc(100%);
      height: 25%;

      display: flex;
      flex-flow: row;
      justify-content: flex-start;
    }
      img {
        width: 133px;
      }

    .visible {
      opacity: 1;
      visibility: visible;
    }

    section {
      width: 100%;
      height: 100%;

      margin-top: -25%;

      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

      .main-section {
        width: 100%;
        max-width: 720px;

        border-radius: 10px;

        padding: 55px 40px;

        display: flex;
        flex-flow: column;
        justify-content: flex-end;
      }

        .main-section > h1 {
          text-align: center !important;
        }

        .main-section > p {
          margin-bottom: 84px;
        }

        .main-section > span {
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          margin-bottom: 32px;
        }

  /* Mobile */
  @media only screen and (max-width: 768px) {
    div {
      width: 45%;
    }

    main {
      width: 100vw;

      padding: 0;
    }

      header {
        width: calc(100%);
        height: 25%;
        padding-top: 35px;

        display: flex;
        flex-flow: row;
        justify-content: center;
      }

      .main-section {
        width: calc(100% - 40px);
        max-width: 720px;

        border-radius: 10px;

        padding: 55px 15px;

        display: flex;
        flex-flow: column;
        justify-content: flex-end;
      }
  }

</style>