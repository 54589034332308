<template>
  <main v-bind:class="isVisible ? 'visible' : null">
    <section>
      <Title size="small" color="grey">
        Você deixou um campo vazio. :(
      </Title>
      <Text size="small">
        Por favor, preencha todas as informações para avançar.
      </Text>
      <Button color="grey" :isNextStep="false" :handler="closeButton">
        Ok
      </Button>
    </section>
  </main>
</template>

<script>
  import store from '../../store';

  import Title from '../interface/Title';
  import Text from '../interface/Text';
  import Button from '../interface/Button';

  export default {
    name: 'ValidationErrorModal',
    components: {
      Title,
      Text,
      Button
    },
    props: {
      isVisible: Boolean
    },
    methods: {
      closeButton () { store.commit('setInvalid') }
    }
  }
</script>

<style scoped>

  main {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    background-color: rgba(222,224,226, 0.4);

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    transition: all 0.4s cubic-bezier(0.65, 0, 0.35, 1) 0s;
    opacity: 0;
    visibility: hidden;
  }

    .visible {
      opacity: 1;
      visibility: visible;
    }

    section {
      width: 100%;
      max-width: 370px;
      height: 100%;
      max-height: 290px;

      background-color: #DEE0E2;
      border-radius: 10px;

      padding: 55px 40px;

      display: flex;
      flex-flow: column;
      justify-content: space-between;

      transition: all 0.2s cubic-bezier(0.65, 0, 0.35, 1) 0s;
      transform: translateY(100vh);
    }

      .visible > section {
        transform: translateY(0);
      }

      section > h1 {
        text-align: center !important;
      }

      span {
        display: flex;
        flex-flow: row;
        justify-content: center;
      }

  /* Mobile */
  @media only screen and (max-width: 768px) {
    div {
      width: 45%;
    }
  }

</style>